import React, { Component } from "react"
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'

class PersonTemplate extends Component {
  render() {
    const person = this.props.data.wpPerson;

    return (
      <Layout>

        <div className="person-nav" />

        <div className="ctn-main uk-padding">
          <ul className="uk-breadcrumb uk-margin-bottom">
            <li><Link to="/"> Homepage </Link></li>
            <li><Link to="/team"> Team </Link></li>
          </ul>

          <div className="team-member uk-card uk-card-default">
              <div className="uk-card-header">
                  <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                      <div className="uk-width-auto uk-margin-right">
                        {(person.teamsACF != undefined || person.teamsACF != null ) &&
                        <img className="" width="100" height="auto" src={person.teamsACF.avatarTeam.sourceUrl} alt={person.title} />
                        }
                      </div>
                      <div className="uk-width-expand">
                          <h3 className="uk-card-title uk-margin-remove-bottom" dangerouslySetInnerHTML={{ __html: person.title }} ></h3>
                          <p>{person.teamsACF !== undefined &&
                          <p dangerouslySetInnerHTML={{ __html: person.teamsACF.role }}></p>
                        }</p>
                          <span className="uk-text-meta uk-margin-remove-top">{person.teamsACF.nativeCountry !== undefined &&
                          <span dangerouslySetInnerHTML={{ __html: "From " + person.teamsACF.nativeCountry }}></span>
                        }</span>
                      </div>
                  </div>
              </div>
              <div className="uk-card-body">
                  {person.teamsACF.bio !== undefined &&
                  <div dangerouslySetInnerHTML={{ __html: person.teamsACF.bio }} />
                  }
              </div>
          </div>

        </div> {/* End .ctn-main.uk-padding */}
      </Layout>
    )
  }
}

export default PersonTemplate

export const pageQuery = graphql`
query($slug: String!) {
  wpPerson(slug: {eq: $slug}) {
    slug
    title
    teamsACF {
      fullName
      bio
      role
      nativeCountry
      avatarTeam {
        sourceUrl
      }
    }
  }
}`
